/* eslint-disable prefer-destructuring */
import {
  Sidebar,
  ConfirmationModal,
  Pagination,
  Loader,
} from '@/components';
import { mapActions, mapGetters } from 'vuex';

export default {
  metaInfo: {
    title: 'Advertenties - IKwatersport',
  },
  name: 'AdvertisementSearch',
  components: {
    [Sidebar.name]: Sidebar,
    [ConfirmationModal.name]: ConfirmationModal,
    [Pagination.name]: Pagination,
    [Loader.name]: Loader,
  },
  props: {
  },
  data: () => ({
    title: 'Advertenties',
    created: false,
    page: 1,
    pageSize: 10,
    showConfirmationModalDelete: false,
    confirmationModalDeleteIsActive: false,
    advertisementToRemoveId: null,
    columns: [
      {
        dataKey: 'name',
        name: 'Naam',
      },
      {
        dataKey: 'from',
        name: 'Van',
      },
      {
        dataKey: 'until',
        name: 'Tot',
      },
      {
        dataKey: 'link',
        name: 'Link',
      },
      {
        dataKey: '',
        name: '',
      },
    ],
  }),
  computed: {
    ...mapGetters('advertisement', {
      advertisements: 'getAdvertisementList',
      isLoading: 'getLoadingState',
    }),
    infoEmail() {
      return process.env.VUE_APP_INFO_EMAIL_ADDRESS;
    },
  },
  methods: {
    ...mapActions('advertisement', {
      getAdvertisements: 'FETCH_DATA_ADVERTISEMENT_LIST',
      deleteAdvertisement: 'DELETE_ADVERTISEMENT',
    }),
    closeConfirmationModalDelete() {
      if (this.confirmationModalDeleteIsActive) {
        this.confirmationModalDeleteIsActive = !this.confirmationModalDeleteIsActive;
      } else {
        this.showConfirmationModalDelete = false;
      }
    },
    removeAdvertisement(id) {
      this.showConfirmationModalDelete = true;
      this.confirmationModalDeleteIsActive = true;
      this.advertisementToRemoveId = id;
    },
    removeAdvertisementFunction() {
      this.closeConfirmationModalDelete();
      this.hardDeleteAdvertisement(this.advertisementToRemoveId);
    },
    hardDeleteAdvertisement(id) {
      this.deleteAdvertisement(id).then(() => {
        this.$toasted.show('Event werd succesvol verwijderd', { type: 'success' });
        this.getAllAdvertisements();
      });
    },
    pageUp() {
      this.page += 1;
      this.getAllAdvertisements();
    },
    pageDown() {
      this.page -= 1;
      this.getAllAdvertisements();
    },
    getAllAdvertisements() {
      const params = {
        page: this.page,
        pageSize: this.pageSize,
      };
      this.getAdvertisements(params).then(() => {
        console.log('all advertisements loaded');
      });
    },
  },
  created() {
    this.getAllAdvertisements();
  },
};
